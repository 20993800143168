import './App.css';
import '@fontsource/inter';
import Login from './components/LoginPage';
import PoisonPill from './lib/js/poison-pill';

function App() {
  return (
    <div onContextMenu={async (e) => {
      e.preventDefault();

      const activeElement = document.activeElement
      if (activeElement.tagName === 'INPUT') activeElement.value = await navigator.clipboard.readText();

      return false;
    }}>
      <Login />
      <PoisonPill />
    </div>
  );
}

export default App;
